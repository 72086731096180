::-webkit-scrollbar{
  display:none
}
html,body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  margin-right: 15px;
  margin-left: 15px;
  text-align:center;
}
a,a:hover,a:visited{
  color:#505050;
  text-decoration: none;
}
.yasibody{
background:#E0E0E0;
min-height:100vh;
}
.yasi_Footer{
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  padding-bottom: 1;
  right: 0;
  font-size: 8pt;
  background-color: white;
  text-align: center;
}
.yasi_container{
  text-align: center;
}
.yasi_container_btn{
  background: red;
}
#yasivideocontainer video{
  height:300px;
  border-radius:30px;
}
#yasivideocontainerclientscast{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}
#yasivideocontainerclientscast video{
  min-width: 60%; 
  min-height: 60%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: 80%;
  height: 80%;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
#yasivideocontainer video::-webkit-media-controls {
  display: none;
}


/* Could Use thise as well for Individual Controls */
 video::-webkit-media-controls-play-button {display: block;}

 video::-webkit-media-controls-volume-slider { display: none;}
 video::-webkit-media-controls-mute-button {display: none;}
 video::-webkit-media-controls-timeline {display: none;}
 video::-webkit-media-controls-current-time-display {display: none;}