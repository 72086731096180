.diya_meet {
    background-color: grey;
    min-width: 100%;
    min-height: 100vh;

    .diya_meet_screen {
        width: 100%;
        white-space: nowrap;
        overflow-x: scroll;

        .video {
            position: relative;
            height: 264px;
            width: auto;
            float: left;

            .background {
                width: 100%;
                height: 100%;
                background-color: white;
                /* Add the blur effect */
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }

            video {
                height: 100%;
                margin: auto auto auto auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .screen {
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }

            .videoframe {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: transparent;
                border: 0px solid salmon;
            }
        }
    }
}

#yasivideocontainerclientscast {
    position: fixed;
    z-index: 7777;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    video {
        height: 100vh;
        width: 100vw;
    }
}



/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}